.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.operationStudent .itemLabel {
  min-width: 5rem;
}
.operationStudent .ci-full .el-input--suffix .el-input__inner {
  padding-right: 3px;
}
.operationStudent .ci-full .el-input input {
  width: 100% !important;
}
.operationStudent .ci-full .smallselect input {
  width: 100% !important;
}
.model .conTip {
  text-align: justify;
  margin-bottom: 20px;
}
.model .itemLabel {
  margin: 10px 0;
}
